import React, { useState } from "react"
import styled from "styled-components"

import { Container } from "../components/global"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const ResetPasswordPage = ({ location }) => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const NOT_SENT = 0;
    const SUCCESS = 1;
    const FAILURE = 2;

    const [state, setState] = useState({
        token: token,
        password: { value: "", isValid: false },
        confirmPassword: { value: "", isValid: false },
        formValid: false,
        formState: NOT_SENT,
    })

    const handleChange = ({ target }) => {
        const { name, value } = target;
        const newState = { ...state, [name]: { value: value, isValid: value ? true : false } }
        newState.formValid = validateForm(newState);
        setState(newState);
    }

    const validateForm = (newState) => {
        const isValid = newState.password.isValid &&
            newState.confirmPassword.isValid;
        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        resetPassword()
    }

    const resetPassword = async () => {
        try {
            const query = JSON.stringify({
                query: `mutation ($input: ResetPasswordInput!) {
                    resetPassword(input: $input){
                        result
                        errors{
                            message
                            code
                        }
                    }
                }`,
                variables: {
                    input: {
                        token: token,
                        password: state.password.value,
                        confirmPassword: state.confirmPassword.value
                    },
                },
            })

            const response = await fetch(`${process.env.GATSBY_API_URL}`, {
                method: "post",
                body: query,
                headers: {
                    "Content-Type": "application/json",
                    "Content-Length": query.length,
                },
            })

            const responseObject = await response.json()
            let result = responseObject.data.resetPassword.result;
            if (result) {
                const newState = { ...state, formState: SUCCESS }
                setState(newState);
            } else {
                const newState = { ...state, formState: FAILURE }
                setState(newState);
            }
        } catch (error) {
            console.warn(error)
            const newState = { ...state, formState: FAILURE }
            setState(newState);
        }
    }
    switch (state.formState) {
        case NOT_SENT:
            return (
                <Layout>
                    <SEO title="Resetare parola" />
                    <Navigation />
                    <StyledContainer>
                        <Wrapper>
                            <Title>Resetare parola</Title>
                            <Form onSubmit={handleSubmit}>
                                <FieldsWrapper>
                                    <TextFieldsWrapper>
                                        <Input
                                            name="password"
                                            type="password"
                                            placeholder="Noua parola"
                                            value={state.password.value}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirmare parola"
                                            value={state.confirmPassword.value}
                                            onChange={handleChange}
                                        />
                                    </TextFieldsWrapper>
                                </FieldsWrapper>
                                <Button disabled={!state.formValid} onclick={async () => { await resetPassword() }}>Resetare parola</Button>
                            </Form>
                        </Wrapper>
                    </StyledContainer>
                    <Footer />
                </Layout>
            );
        case SUCCESS:
            return (<Layout>
                <SEO title="Resetare parola" />
                <Navigation />
                <StyledContainer>
                    <Wrapper>
                        <Title>Resetare parola</Title>
                        <Text>Parola a fost resetata cu succes.</Text>
                    </Wrapper>
                </StyledContainer>
                <Footer />
            </Layout>);
        case FAILURE:
            return (<Layout>
                <SEO title="Resetare parola" />
                <Navigation />
                <StyledContainer>
                    <Wrapper>
                        <Title>Resetare parola</Title>
                        <Text>Parola nu a fost resetata.</Text>
                    </Wrapper>
                </StyledContainer>
                <Footer />
            </Layout>);
    }
}

export default ResetPasswordPage

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TextFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20em;
`

const Title = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;

  input,
  button {
    margin-top: 1em;
  }
`

const Input = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 100%;
  }
`

const Button = styled.button`
  align-self: center;
  padding: 0 2em;
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.disabled ? '#CCCCCC' : props.theme.color.secondary};
  border-radius: 4px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
`

const Text = styled.p`
  font-size: 16px;
  letter-spacing: 0px;
  margin-bottom: 16px;
`